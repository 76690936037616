


















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import GlobalErrorSnackBar from "@/components/GlobalErrorSnackBar.vue";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { GlobalErrorSnackBar, Loading },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  computed: {
    items() {
      if (!this.data) {
        return;
      }
      return this.data.faqItems.map((item) => {
        return {
          question: item.question.find(({ language }) => language === "en").content,
          answer: item.answer.find(({ language }) => language === "en").content,
        };
      });
    },
  },
  async created() {
    this.loading = true;
    this.data = await coreApiClient.call("setting", "get");
    this.loading = false;
  },
});
